.pin_confirm_form {
  display: flex;
  flex-direction: column;
  padding-left: 3em;
  padding-right: 3em;
}

.pin_confirm_form .messages {
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 7em;
}

.pin_confirm_form .messages h3 {
  margin-bottom: 0.5em;
}

.buttons {
  display: flex;
  justify-content: center;
  min-height: 4em;
  padding-bottom: 6em;
  white-space: nowrap;
}
