.pin_confirm_page {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  overflow-y: auto;
}

.pin_confirm_page .profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2em;
  border-bottom: 0.05em solid #999999;
  margin-top: 3em;
  margin-bottom: 3em;
}

.pin_confirm_page .profile h1 {
  margin: 1em;
}
